import { document } from '../../../globals';
import CartIcon from '../../../header/CartIcon';
import CartAmountUpdater from './CartAmountUpdater';

let miniCartInstance: CartIcon | null = null;

const updateMiniCart = () => {
    if (!document) {
        return;
    }

    if (!miniCartInstance) {
        miniCartInstance = new CartIcon(new CartAmountUpdater(document.body));
    }

    miniCartInstance.init().catch(() => null);
};

export default updateMiniCart;
