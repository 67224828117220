type HourDigit = string;
type MinuteDigit = string;
type SecondDigit = string;

type TimeDifference = [[HourDigit, HourDigit], [MinuteDigit, MinuteDigit], [SecondDigit, SecondDigit]];

export default class Countdown {
    public static init(container: Element, endDate: Date): void {
        setInterval((): void => {
            Countdown.updateCountdown(endDate, container);
        }, 1000);
    }

    /**
     * Returns the difference between the current time and the given time
     */
    public static getTimeDifferenceArray(countDownDate: Date, now: Date): TimeDifference {
        const distance = countDownDate.getTime() - now.getTime();
        const atLeastZero = (input: number) => (Math.max(0, input));

        return [
            Countdown.getDigits(atLeastZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))),
            Countdown.getDigits(atLeastZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))),
            Countdown.getDigits(atLeastZero(Math.floor((distance % (1000 * 60)) / 1000))),
        ];
    }

    /**
     * Splits a number into two digits
     */
    public static getDigits(number: number): [string, string] {
        const twoDigits = (number < 10) ? `0${number.toString()}` : number.toString();
        return [
            twoDigits[0] ?? '0',
            twoDigits[1] ?? '0',
        ];
    }

    private static updateCountdown(countdownEnd: Date, countdownTimerElement: Element): void {
        const setInnerHtmlIfExists = (element: Element | null, text: string): void => {
            if (element) {
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = text;
            }
        };

        const countdownArray = Countdown.getTimeDifferenceArray(countdownEnd, new Date());

        const hours = countdownArray[0];

        const countDownHourLeft = countdownTimerElement.querySelector('.countdownHourLeft');
        setInnerHtmlIfExists(countDownHourLeft, hours[0]);
        const countdownHourRight = countdownTimerElement.querySelector('.countdownHourRight');
        setInnerHtmlIfExists(countdownHourRight, hours[1]);

        const minutes = countdownArray[1];
        const countdownMinuteLeft = countdownTimerElement.querySelector('.countdownMinuteLeft');
        setInnerHtmlIfExists(countdownMinuteLeft, minutes[0]);
        const countdownMinuteRight = countdownTimerElement.querySelector('.countdownMinuteRight');
        setInnerHtmlIfExists(countdownMinuteRight, minutes[1]);

        const seconds = countdownArray[2];
        const countdownSecondLeft = countdownTimerElement.querySelector('.countdownSecondLeft');
        setInnerHtmlIfExists(countdownSecondLeft, seconds[0]);
        const countdownSecondRight = countdownTimerElement.querySelector('.countdownSecondRight');
        setInnerHtmlIfExists(countdownSecondRight, seconds[1]);
    }
}
