import Countdown from '../util/Countdown';
import { document } from '../globals';

const initCountdown = (): void => {
    const countdownTimerElement = document?.getElementsByClassName('js-notificationCountdown')[0];
    // Check if element exists
    if (!countdownTimerElement) {
        return;
    }
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    Countdown.init(countdownTimerElement, endDate);
};

export default initCountdown;
