import fetch from '../api/fetch';
import type CartAmountUpdater from '../checkout/cart/binder/CartAmountUpdater';

// eslint-disable-next-line camelcase
const responseHasCartAmount = (response: unknown): response is { cart_amount: number } => 'cart_amount' in (response as { cart_amount?: number });

export default class CartIcon {
    public constructor(private readonly updater: CartAmountUpdater) {
    }

    public async init(): Promise<void> {
        let cartCount: number;
        try {
            const data = await fetch('ajax/cart-count');
            const json = await data.json() as unknown;

            if (responseHasCartAmount(json)) {
                cartCount = json.cart_amount;
            } else {
                cartCount = 0;
            }
        } catch (e) {
            cartCount = 0;
        }

        this.updater.updateWithAmount(cartCount);
    }
}
