import { document, window } from '../globals';
import { isBreakpointLG } from './sizes';
import scrollToElement from './scrollToElement';

export interface CollapseEvent extends Event {
    detail?: {
        opened?: boolean;
    };
}

export default class Collapse {
    public static readonly COLLAPSE_EVENT_NAME = 'yspCollapseEvent';

    public static readonly COLLAPSE_PARENT_CLASS = 'ysp-collapse';

    private static readonly COLLAPSE_TRIGGER_CLASS = 'ysp-collapse__item';

    private static readonly COLLAPSE_MOBILE_CLASS = 'ysp-collapse--mobile';

    private static readonly COLLAPSE_OUT_CLASS = 'ysp-collapse--out';

    private static readonly COLLAPSE_IN_CLASS = 'ysp-collapse--in';

    private static readonly COLLAPSE_DATA_GROUP = 'data-ysp-collapse-group';

    private static readonly COLLAPSE_DATA_SCROLL_BACK = 'data-ysp-collapse-scroll-back';

    private static readonly COLLAPSE_DATA_LISTENER = 'data-ysp-collapse-listener';

    public static init(): void {
        Collapse.removeBinds();
        Collapse.bind();
        Collapse.bindWindowResize();
    }

    private static bindWindowResize(): void {
        window?.addEventListener('resize', () => {
            Collapse.removeBinds();
            Collapse.bind();
        });
    }

    private static bind(): void {
        const collapseParents = document?.getElementsByClassName(Collapse.COLLAPSE_PARENT_CLASS);
        if (!(collapseParents instanceof HTMLCollection)) {
            return;
        }

        Array.from(collapseParents).forEach((el) => {
            if (!(el instanceof HTMLElement)) {
                return;
            }

            if (el.classList.contains(Collapse.COLLAPSE_MOBILE_CLASS) && isBreakpointLG()) {
                return;
            }

            if (el.hasAttribute(Collapse.COLLAPSE_DATA_LISTENER)) {
                el.addEventListener(Collapse.COLLAPSE_EVENT_NAME, Collapse.handleCollapse);
            }

            const collapseTrigger = el.querySelectorAll(`.${Collapse.COLLAPSE_TRIGGER_CLASS}`);
            Array.from(collapseTrigger).forEach((item) => {
                item.addEventListener('click', Collapse.handleCollapse);
            });
        });
    }

    private static handleCollapse(event: CollapseEvent) {
        const { currentTarget } = event;
        if (!(currentTarget instanceof HTMLElement)) {
            return;
        }

        const collapseParent = currentTarget.closest(`.${Collapse.COLLAPSE_PARENT_CLASS}`);
        if (!(collapseParent instanceof HTMLElement)) {
            return;
        }

        if (event.detail && event.detail.opened === true) {
            Collapse.closeAllSameGroup(collapseParent);
            Collapse.open(collapseParent);
            return;
        }

        if (event.detail && event.detail.opened === false) {
            Collapse.close(collapseParent);
            return;
        }

        if (collapseParent.classList.contains(Collapse.COLLAPSE_OUT_CLASS)) {
            Collapse.close(collapseParent);
        } else {
            Collapse.closeAllSameGroup(collapseParent);
            Collapse.open(collapseParent);
        }
    }

    private static removeBinds(): void {
        const collapseParents = document?.getElementsByClassName(Collapse.COLLAPSE_PARENT_CLASS);
        if (!(collapseParents instanceof HTMLCollection)) {
            return;
        }

        Array.from(collapseParents).forEach((el) => {
            if (!(el instanceof HTMLElement)) {
                return;
            }

            el.removeEventListener(Collapse.COLLAPSE_EVENT_NAME, Collapse.handleCollapse);

            const collapseTrigger = el.querySelectorAll(`.${Collapse.COLLAPSE_TRIGGER_CLASS}`);
            Array.from(collapseTrigger).forEach((item) => {
                item.removeEventListener('click', Collapse.handleCollapse);
            });
        });
    }

    private static open(el: HTMLElement): void {
        el.classList.remove(Collapse.COLLAPSE_IN_CLASS);
        el.classList.add(Collapse.COLLAPSE_OUT_CLASS);
    }

    private static close(el: HTMLElement): void {
        el.classList.remove(Collapse.COLLAPSE_OUT_CLASS);
        el.classList.add(Collapse.COLLAPSE_IN_CLASS);

        if (el.hasAttribute(Collapse.COLLAPSE_DATA_SCROLL_BACK)) {
            const id = el.getAttribute('id');

            if (id) {
                scrollToElement(id);
            }
        }
    }

    private static closeAllSameGroup(el: HTMLElement): void {
        const dataGroupValue = el.getAttribute(Collapse.COLLAPSE_DATA_GROUP);
        if (dataGroupValue === null) {
            return;
        }
        const sameGroupElements = document?.querySelectorAll(`[${Collapse.COLLAPSE_DATA_GROUP}=${String(dataGroupValue)}]`);
        if (!(sameGroupElements instanceof NodeList)) {
            return;
        }

        sameGroupElements.forEach((sameGroupElement) => {
            if (sameGroupElement instanceof HTMLElement) {
                this.close(sameGroupElement);
            }
        });
    }
}
