import $ from 'jquery';
import Collapse from '../util/Collapse';
import Menu from './Menu';
import UspScroller from './UspScroller';
import { document } from '../globals';
import initCountdown from './initCountdown';
import updateMiniCart from '../checkout/cart/binder/updateMiniCart';

$(() => {
    if (!document) {
        return;
    }
    Collapse.init();
    initCountdown();
    UspScroller.init();
    new Menu().init();
    updateMiniCart();
});
