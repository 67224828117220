import debounce from 'debounce';
import { document, window } from '../globals';

export default class UspScroller {
    public static init(): void {
        UspScroller.rotateUsps();
        UspScroller.rotateArrow();
        UspScroller.setHeight();
        UspScroller.onResize();
        UspScroller.observeChanges();
    }

    private static rotateUsps(): void {
        const elements = document?.getElementsByClassName('js-usp-banner-main-item') || [];
        const containers = document?.getElementsByClassName('js-usp-banner-main') || [];

        if (containers.length === 0) {
            return;
        }

        let index = 0;
        let isPaused = false;

        setInterval(() => {
            if (!isPaused) {
                Array.from(elements).forEach((element) => {
                    element.classList.remove('is-active');
                });

                index += 1;
                if (index >= elements.length) {
                    index = 0;
                }

                elements[index]?.classList.add('is-active');
            }
        }, 4000);

        Array.from(containers).forEach((container) => {
            container.addEventListener('click', () => {
                isPaused = !isPaused;
            });
        });
    }

    private static rotateArrow(): void {
        const elements = document?.getElementsByClassName('js-usp-banner-main') || [];
        const arrows = document?.getElementsByClassName('js-usp-banner-main-btn') || [];
        const container = document?.querySelector('.js-usp-banner-main') as HTMLElement;

        if (elements.length === 0 || arrows.length === 0) {
            return;
        }

        Array.from(arrows).forEach((arrow) => {
            arrow.addEventListener('click', (event) => {
                const { target } = event;
                if (!(target instanceof Element)) {
                    return;
                }
                target.classList.toggle('rotate-180');
                container?.classList.toggle('is-open');
            });
        });
    }

    private static setHeight(): void {
        const uspContainer = document?.querySelector(
            '.usp-banner-main',
        ) as HTMLElement;
        const uspElements: HTMLElement[] = Array.from(
            document?.querySelectorAll('.usp-banner-main-item') || [],
        );

        if (!uspContainer || !uspElements) {
            return;
        }

        for (let i = 0; i < uspElements.length; i += 1) {
            const uspElement = uspElements[i];
            if (uspElement) {
                uspElement.style.height = '';
            }
        }

        const maxHeight = Math.max(
            ...uspElements.map((uspElement) => uspElement.offsetHeight),
        );

        for (let i = 0; i < uspElements.length; i += 1) {
            const uspElement = uspElements[i];
            if (uspElement) {
                uspElement.style.height = `${maxHeight}px`;
            }
        }

        uspContainer.style.height = `${maxHeight}px`;
    }

    private static observeChanges(): void {
        const observer = new MutationObserver(() => UspScroller.setHeight());
        const deadlineText = document?.getElementsByClassName('js-usp-banner-main-item-name--deadline') || [];
        const config = { characterData: true, childList: true };

        Array.from(deadlineText).forEach((elem) => observer.observe(elem, config));
    }

    private static onResize(): void {
        window?.addEventListener(
            'resize',
            debounce(() => {
                const uspElements: HTMLElement[] = Array.from(
                    document?.querySelectorAll('.js-mobileBannerItem') || [],
                );

                for (let i = 0; i < uspElements.length; i += 1) {
                    const uspElement = uspElements[i];
                    if (uspElement) {
                        uspElement.style.removeProperty('height');
                    }
                }

                this.setHeight();
            }, 250),
        );
    }
}
