export default class CartAmountUpdater {
    private numberElements: Element[];
    private circleElements: Element[];
    private dataElements: Element[];

    public constructor(container: HTMLElement | undefined) {
        if (!container) {
            throw Error('Could not find element needed to show cart count');
        }

        this.circleElements = Array.from(container.getElementsByClassName('js-cartBox-circle'));
        this.numberElements = Array.from(container.getElementsByClassName('js-cartBox-number'));
        this.dataElements = Array.from(container.querySelectorAll('[data-cart-amount]'));
    }

    public updateWithAmount(amount: number): void {
        this.updateNumber(amount);
        this.toggleBigCheckoutVisualization(amount > 9);
    }

    private updateNumber(amount: number) {
        this.dataElements.forEach((el: Element): void => {
            if (!(el instanceof HTMLElement)) {
                return;
            }
            // eslint-disable-next-line no-param-reassign
            el.dataset.cartAmount = amount.toString();
        });

        this.numberElements.forEach((el:Element): void => {
            // eslint-disable-next-line no-param-reassign
            el.innerHTML = amount.toString();
        });
    }

    private toggleBigCheckoutVisualization(isBigNumber: boolean): void {
        this.circleElements.forEach((el): void => {
            el.classList.toggle('cartBox__circle--largeOrder', isBigNumber);
        });
    }
}
